
import { computed, defineComponent, ref } from 'vue'
import type { StatusType, TableHeaders } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'
import { getTableData } from '@/services/tableService'
import { filterItemsByValues } from '@/services/utils'
import { formatDate } from '@/services/dateTimeService'
import LookupHistoryTable from '@/components/pages/services/emailLookup/history/LookupHistoryTable.vue'
import LookupHistoryFilter from '@/components/pages/services/emailLookup/history/LookupHistoryFilter.vue'
import LookupHistoryEmpty from '@/components/pages/services/emailLookup/history/LookupHistoryEmpty.vue'

type History = {
  email: string
  status: StatusType
  deliverability: StatusType
  disposable: string
  dateCreated: string
}

export default defineComponent({
  name: 'ServicesEmailLookupHistory',
  components: { LookupHistoryEmpty, LookupHistoryFilter, LookupHistoryTable },
  setup() {
    const { isEmptyMode } = useModes()
    const search = ref('')
    const selected = ref<History[]>([])
    const tableItems: History[] = getTableData('emailLookupHistory')
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Email', value: 'email-slot' },
      { text: 'Status', value: 'status-slot' },
      { text: 'Deliverability', value: 'deliverability-slot' },
      { text: 'Disposable', value: 'disposable' },
      { text: 'Date created', value: 'dateCreated', format: (val: string) => formatDate(val) },
    ])

    const filteredTableItems = computed<History[]>(() =>
      tableItems.filter(({ email, status, deliverability, disposable }) =>
        filterItemsByValues(search.value, [
          email,
          status.name,
          deliverability.name,
          disposable,
        ])
      )
    )

    return {
      isEmptyMode,
      search,
      selected,
      tableItems,
      tableHeaders,
      filteredTableItems,
    }
  },
})
