import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_lookup_history_dropdown = _resolveComponent("lookup-history-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-email-slot": _withCtx(({ row }) => [
      _createElementVNode("span", {
        class: "blue-on-hover pointer",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openViewDetailsModal && _ctx.openViewDetailsModal(...args)))
      }, _toDisplayString(row.email), 1)
    ]),
    "body-cell-status-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(row.status)), null, 16)
    ]),
    "body-cell-deliverability-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(row.deliverability)), null, 16)
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_lookup_history_dropdown, { selected: _ctx.selected }, null, 8, ["selected"])
    ]),
    _: 1
  }))
}