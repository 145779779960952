
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import LookupHistoryDropdown from '@/components/pages/services/emailLookup/history/LookupHistoryDropdown.vue'
import useEmailLookupOpenModal from '@/compositions/services/useEmailLookupOpenModal'

export default defineComponent({
  name: 'LookupHistoryTable',
  components: { LookupHistoryDropdown, TmStatus, TmTable },
  props: {
    selected: {
      type: Array,
    },
  },
  setup() {
    const { openViewDetailsModal } = useEmailLookupOpenModal()

    return {
      openViewDetailsModal,
    }
  },
})
